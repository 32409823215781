import React from 'react';
import { View } from 'react-native';

import { flexRow, alignItemsCenter, mv5, mr10 } from '../../common/commonStyles';
import { colors } from '../../utils/colors';
import { H4TtmSemiBoldGreen } from '../commonText';
import { Verified } from '../svgImages';
import { UploadingStateEnum } from './UploadStateEnum';

interface Props {
  state: UploadingStateEnum;
}

export const MediaUploadStateComponent: React.FC<Props> = ({ state }) => {
  switch (state) {
    case UploadingStateEnum.COMPLETED:
      return (
        <View style={[flexRow, alignItemsCenter, mv5]}>
          <View style={mr10}>
            <Verified height={20} width={20} color={colors.success} />
          </View>
          <H4TtmSemiBoldGreen>UPLOAD COMPLETE</H4TtmSemiBoldGreen>
        </View>
      );
    case UploadingStateEnum.UPLOADING:
      return (
        <View style={[flexRow, alignItemsCenter, mv5]}>
          <H4TtmSemiBoldGreen style={{ color: colors.warning }}>UPLOADING</H4TtmSemiBoldGreen>
        </View>
      );
    case UploadingStateEnum.FAILED:
      return (
        <View style={[flexRow, alignItemsCenter, mv5]}>
          <H4TtmSemiBoldGreen style={{ color: colors.danger }}>UPLOAD FAILED</H4TtmSemiBoldGreen>
        </View>
      );
    case UploadingStateEnum.INVALID:
      return (
        <View style={[flexRow, alignItemsCenter, mv5]}>
          <H4TtmSemiBoldGreen style={{ color: colors.danger }}>INVALID FORMAT</H4TtmSemiBoldGreen>
        </View>
      );
    default:
      return null;
  }
};
