import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { useApprovalRequestsList } from '~/api/hooks/patientDataHandling/useApprovalRequestsList';

import { ConsultationModel } from '~/api/models/consultations/models/ConsultationModel';
import { apiApproveConsultation, apiDeclineConsultation } from '~/api/services/consultations/request';
import { MissingConsultationRequestError } from '~/classes/errors/MissingConsultationError';
import { ConfirmationAlert, SuccessAlert } from '~/common/commonMethods';
import { mt10 } from '~/common/commonStyles';
import { Button, OutlineButton } from '~/components/commonButton';
import { H6NsRegularBlack } from '~/components/commonText';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import { ModalName } from '~/components/modals/constants/ModalNames';
import { NavType } from '~/navigation/types';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { transformLabel } from '~/utils/labelUtil';
import { labels } from '~/utils/labels';
import { appointmentLabels } from '~/utils/labels/appointments';
import { bookingLabels } from '~/utils/labels/booking';
import { getAccountName } from '~/utils/personalDetailsUtils';
import { screenName } from '~/utils/screenName';

interface Props {
  consultation: ConsultationModel;
  onReady: () => void;
}

export const OnDemandApprovalRequestButtons: React.FC<Props> = ({ consultation, onReady }) => {
  const { navigate } = useNavigation<NavType>();
  const { closeModalByName } = useModalManager();
  const [loading, setLoading] = useState(false);
  const { openModal } = useModalManager();
  const { getRequests } = useApprovalRequestsList();

  const approveRequest = async () => {
    try {
      setLoading(true);
      await apiApproveConsultation({
        id: consultation.id,
      }).catch(() => new MissingConsultationRequestError(consultation.id));

      ConfirmationAlert([bookingLabels.onDemand.successMessage], {
        title: bookingLabels.appointmentApproved,
        okTitle: bookingLabels.viewCalendar,
        cancelTitle: labels.continue,
        okFunction: () => {
          closeModalByName(ModalName.ApprovalRequest);
          navigate(screenName.CalendarPage, { screen: screenName.CalendarAppointments });
        },
        cancelFunction: () => {
          closeModalByName(ModalName.ApprovalRequest);
        },
      });
    } catch (e) {
      ErrorAlert(e);
    } finally {
      setLoading(false);
      onReady();
    }
  };

  const declineRequest = async () => {
    closeModalByName(ModalName.ApprovalRequest);
    openModal(ModalName.RejectConsultationReasonModal, {
      consultation,
      title: appointmentLabels.declineConsultation,
      description: appointmentLabels.declineReason,
      declineApi: apiDeclineConsultation,
      buttonTitle: appointmentLabels.decline,
      onReady: async () => {
        onReady();
        SuccessAlert(['Consultation successfully declined']);
        await getRequests();
      },
    });
  };

  return (
    <>
      <H6NsRegularBlack>
        {transformLabel(appointmentLabels.onDemandRequest, { patient: getAccountName(consultation.patient) })}
      </H6NsRegularBlack>
      <Button success label={labels.approve} funCallback={approveRequest} style={mt10} disabled={loading} />
      <OutlineButton danger label={labels.decline} funCallback={declineRequest} style={mt10} disabled={loading} />
    </>
  );
};
