import React, { useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import { BillingHistoryList } from './BillingHistoryList';
import { IconButton } from '../buttons/IconButton';
import { BillingHistoryFilterMenu } from '../common/billing/BillingHistoryFilterMenu';
import { useBillingHistoryContext } from '../common/billing/contexts/BillingHistoryContext';
import { BillingHistoryProvider } from '../common/billing/contexts/BillingHistoryProvider';
import { ErrorAlert } from '../modals/ErrorAlert';
import { ModalName } from '../modals/constants/ModalNames';
import { FilterIcon } from '../svgImages';

import { usePolling } from '~/api/hooks/Polling';
import { useFeatureAccess } from '~/api/hooks/subscriptions/FeatureAccessHook';
import { checkIsPremiumPlan, useSubscriptions } from '~/api/hooks/subscriptions/SubscriptionHook';
import { useSubscriptionPlanListing } from '~/api/hooks/subscriptions/SubscriptionListingHook';
import { useSubscriptionManagement } from '~/api/hooks/subscriptions/SubscriptionManagementHook';
import { FeatureAccessEnum } from '~/api/models/subscriptions/constants/FeatureAccessEnum';
import { SubscriptionPriceModel } from '~/api/models/subscriptions/models/SubscriptionPriceModel';
import { SubscriptionPlanMeResponse } from '~/api/models/subscriptions/responses/SubscriptionPlanMeResponse';
import { apiCancelSubscription } from '~/api/services/subscriptions';
import { ConfirmationAlert, hideSuccessOrErrorPopup, InprogressAlert, SuccessAlert } from '~/common/commonMethods';
import { mb10, mb20 } from '~/common/commonStyles';
import { H3TtmSemiBoldBlack, H6NsRegularBlack } from '~/components/commonText';
import { SubscriptionPaymentCard } from '~/components/subscriptions/SubscriptionPaymentCard';
import { SubscriptionPlanCard } from '~/components/subscriptions/SubscriptionPlanCard';
import { TabsComponent } from '~/components/tabs/TabsComponent';
import { StripePaymentMethodRef } from '~/integrations/stripe/interface';
import { StripePaymentMethod } from '~/integrations/stripe/paymentMethod';
import { StripePaymentMethodTypeEnum } from '~/integrations/stripe/paymentMethod/constants/StripePaymentMethodTypeEnum';
import { useModalManager } from '~/providers/modal/ModalManagementContext';
import { Column, Grid, Row } from '~/theme/components/grid';
import { isNative, isPatientVersion } from '~/utils/buildConfig';
import { colors } from '~/utils/colors';
import { useBreakpoints } from '~/utils/hooks/GridHook';
import { labels } from '~/utils/labels';
import { profileLabels } from '~/utils/labels/profile';

interface Props {
  title?: React.ReactNode;
}

export const SubscriptionPlanListing: React.FC<Props> = ({ title }) => {
  return (
    <BillingHistoryProvider>
      <SubscriptionPlanList title={title} />
    </BillingHistoryProvider>
  );
};

export const SubscriptionPlanList: React.FC<Props> = ({ title }) => {
  const { fetchFeatureAccess } = useFeatureAccess();
  const paymentMethod = useRef<StripePaymentMethodRef>();
  const [selectedPlanMobile, setSelectedPlanMobile] = useState<string>();
  const { isFeatureDisabled } = useFeatureAccess();

  const { purchasePlan, upgradePlan, resumeSubscription, manageSubscription, subscriptions } =
    useSubscriptionManagement({
      stripeRef: paymentMethod.current,
    });
  const { subscriptionPlans } = useSubscriptionPlanListing({ subscriptions });
  const { currentPlan, getCurrentSubscriptionPlan } = useSubscriptions();
  const { isMobile } = useBreakpoints();
  const { breakpoint } = useBreakpoints();

  let columnMarginStyle = {};

  if (breakpoint !== 'xl') {
    columnMarginStyle = { marginTop: 25 };
  }

  const { openModal, closeModalByName } = useModalManager();

  const { form } = useBillingHistoryContext();

  const isFreePlan = useMemo(() => {
    return !currentPlan?.stripe_subscription_id;
  }, [currentPlan]);

  const extractFirstWord = (str: string) => {
    return str?.split(' ')[0];
  };

  const activePlanMobile = useMemo(() => {
    return (
      (selectedPlanMobile && subscriptionPlans?.find((plan) => plan.type === selectedPlanMobile)) ||
      (subscriptionPlans?.length > 1 ? subscriptionPlans[1] : null) ||
      (currentPlan && subscriptionPlans?.find((plan) => plan.type === currentPlan.subscription_type))
    );
  }, [selectedPlanMobile, currentPlan, subscriptionPlans]);

  const activePlanTypeMobile = useMemo(() => {
    return activePlanMobile?.type;
  }, [selectedPlanMobile, currentPlan, subscriptionPlans]);

  const refreshPlans = async () => {
    await getCurrentSubscriptionPlan();
    await fetchFeatureAccess();
  };

  const tryResumeSubscription = async (myPlan: SubscriptionPlanMeResponse) => {
    try {
      showInProgress({ resuming: true });
      await resumeSubscription(undefined, myPlan.stripe_subscription_id);
    } catch {}
  };

  const [initialPlan, setInitialPlan] = useState<SubscriptionPlanMeResponse | null>(null);
  const [actionType, setActionType] = useState<'upgrade' | 'purchase' | null>(null);
  const actionTypeRef = useRef<'upgrade' | 'purchase' | null>(null);

  const fetchInitialPlan = async () => {
    try {
      const plan = await getCurrentSubscriptionPlan();
      setInitialPlan(plan);
    } catch (error) {
      console.error('Error fetching initial plan:', error);
    }
  };

  useEffect(() => {
    fetchInitialPlan();
  }, [initialPlan]);

  useEffect(() => {
    actionTypeRef.current = actionType;
  }, [actionType]);

  const pollPlan = async (): Promise<boolean> => {
    try {
      const updatedPlan = await getCurrentSubscriptionPlan();

      if (actionTypeRef.current === 'upgrade') {
        if (checkIsPremiumPlan(currentPlan) && updatedPlan.interval !== initialPlan?.interval) {
          SuccessAlert(['You have successfully upgraded your Digimed subscription']);
          stopPolling();
          return true;
        }
      } else if (actionTypeRef.current === 'purchase') {
        if (updatedPlan.subscription_type !== initialPlan?.subscription_type) {
          SuccessAlert(['Congratulations, you are now a Digimed Premium member.']);
          stopPolling();
          return true;
        }
      }

      return false;
    } catch (error) {
      ErrorAlert(error);
    }
  };

  const { startPolling, stopPolling } = usePolling(pollPlan, 2000);

  const handlePolling = () => {
    startPolling({ immediate: true });

    setTimeout(() => {
      stopPolling();
    }, 120000);
  };

  const tryUpgradePlan = async (planPrice: SubscriptionPriceModel) => {
    try {
      showInProgress();

      setInitialPlan(currentPlan);

      const newActionType = 'upgrade';
      setActionType(newActionType);

      await upgradePlan(planPrice, currentPlan.stripe_subscription_id);

      showWaitingForBackend();

      handlePolling();
    } catch (error) {
      hideInProgressAlerts();
    }
  };

  const tryPurchasePlan = async (planPrice: SubscriptionPriceModel) => {
    try {
      showInProgress();
      const newActionType = 'purchase';
      setActionType(newActionType);

      await purchasePlan(planPrice);
      showWaitingForBackend();

      handlePolling();
    } catch (error) {
      hideInProgressAlerts();
    }
  };

  const cancelPlan = async () => {
    try {
      await apiCancelSubscription(currentPlan.stripe_subscription_id);
      SuccessAlert(
        ['Your subscription has been cancelled and will not be renewed in the next billing cycle.'],
        '',
        '',
        async () => {
          await refreshPlans();
        }
      );
    } catch (e) {
      ErrorAlert(e);
      throw e;
    }
  };

  const cancelOrManagePlan = () => {
    if (isNative()) {
      manageSubscription(currentPlan);
    } else {
      return new Promise<boolean>((resolve) => {
        ConfirmationAlert(
          [
            'Are you sure you want to turn off auto-renewal?',
            'This will mean that the subscription will not be renewed on the next payment date.',
          ],
          {
            title: 'Auto-renewal',
            okTitle: 'Turn off',
            cancelTitle: 'BACK',
            okFunction: () => {
              cancelPlan()
                .then(() => resolve(true))
                .catch(() => resolve(false));
            },
            cancelFunction: () => resolve(false),
          }
        );
      });
    }
  };

  const showInProgress = (options?: { resuming: boolean }) => {
    InprogressAlert(
      [
        options?.resuming
          ? 'Please wait while we resume your subscription'
          : 'Please wait while we get your selected subscription details',
      ],
      { block: true }
    );
  };

  const showWaitingForBackend = () => {
    InprogressAlert([
      'We have managed to purchase your subscription.',
      'Please bear with us while we get everything ready for you.',
    ]);
  };

  const hideInProgressAlerts = () => {
    hideSuccessOrErrorPopup();
  };
  return (
    <Grid>
      {isFeatureDisabled(FeatureAccessEnum.IBAN) ? null : (
        <>
          <Row style={mb20}>
            {title ? (
              <Column>{title}</Column>
            ) : (
              <>
                <Column span={{ xs: 12, lg: 4 }} style={mb10}>
                  <H3TtmSemiBoldBlack>
                    {isFreePlan ? profileLabels.upgradeSubscriptionPlan : profileLabels.manageSubscriptionPlan}
                  </H3TtmSemiBoldBlack>
                  <H6NsRegularBlack>{profileLabels.manageSubscriptionPlanSubtitle}</H6NsRegularBlack>
                </Column>
                <Column span={{ xs: 12, lg: 4 }} style={mb10}>
                  {!currentPlan?.is_trial && isFreePlan ? null : <SubscriptionPaymentCard plan={currentPlan} />}
                </Column>
              </>
            )}
          </Row>
          {isMobile ? (
            <Row style={mb20}>
              <Column>
                <TabsComponent
                  items={
                    subscriptionPlans?.map((plan) => ({
                      title: extractFirstWord(plan.plan_title),
                      key: plan.type,
                      onPress: (key) => {
                        setSelectedPlanMobile(key);
                      },
                    })) || []
                  }
                  active={activePlanTypeMobile}
                  compact
                />
              </Column>
            </Row>
          ) : null}
          <Row>
            {isMobile ? (
              <Column style={mb20}>
                {activePlanMobile ? (
                  <SubscriptionPlanCard
                    plan={activePlanMobile}
                    currentPlan={currentPlan}
                    purchasePlan={tryPurchasePlan}
                    cancelOrManagePlan={cancelOrManagePlan}
                    upgradePlan={tryUpgradePlan}
                    resumePlan={tryResumeSubscription}
                  />
                ) : null}
              </Column>
            ) : (
              subscriptionPlans?.map((plan) => (
                <Column
                  key={plan.type}
                  span={{ xs: 12, md: 10, lg: 6, xl: 4 }}
                  style={[columnMarginStyle, { minWidth: 350 }]}>
                  <SubscriptionPlanCard
                    plan={plan}
                    currentPlan={currentPlan}
                    purchasePlan={tryPurchasePlan}
                    cancelOrManagePlan={cancelOrManagePlan}
                    upgradePlan={tryUpgradePlan}
                    resumePlan={tryResumeSubscription}
                  />
                </Column>
              ))
            )}
          </Row>
          {!isPatientVersion() ? <View style={styles.horizontalLine} /> : null}
        </>
      )}

      {isPatientVersion() ? null : (
        <>
          <Row>
            <Column span={{ xs: 12, md: 8 }}>
              <View
                style={{
                  marginVertical: 20,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <H3TtmSemiBoldBlack>{labels.billingHistory}</H3TtmSemiBoldBlack>
                {isMobile ? (
                  <IconButton
                    onPress={() => {
                      openModal(ModalName.FilterMenuModal, {
                        onHide: () => {
                          closeModalByName(ModalName.FilterMenuModal);
                        },
                        form,
                      });
                    }}>
                    <FilterIcon width="19" height="16" />
                  </IconButton>
                ) : (
                  <BillingHistoryFilterMenu />
                )}
              </View>

              <View style={isMobile ? { height: 300 } : { minHeight: 850 }}>
                <BillingHistoryList />
              </View>
            </Column>
          </Row>
        </>
      )}
      <StripePaymentMethod
        ref={paymentMethod}
        type={StripePaymentMethodTypeEnum.PAYMENT}
        title="Upgrade subscription"
      />
    </Grid>
  );
};

const styles = StyleSheet.create({
  horizontalLine: {
    borderBottomColor: colors.lightPurple2,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    paddingBottom: 16,
    marginBottom: 16,
  },
});
