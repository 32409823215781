import * as Device from 'expo-device';
import { useEffect, useRef, useState } from 'react';
import { BiometryType } from 'react-native-biometrics';

import { useRegistrationSteps } from './RegistrationSteps';
import { apiBiometricLogin, apiEnrollDevice, apiUnenrollDevice, apiUseRole } from '../services/authorisation';

import { SuccessAlert } from '~/common/commonMethods';
import { ErrorAlert } from '~/components/modals/ErrorAlert';
import Biometrics from '~/integrations/biometrics';
import { UserLoginDetailsModel } from '~/redux/models/userDetails/UserLoginDetailsModel';
import { UPDATE_LOGIN_DETAILS } from '~/redux/reducers/userDetailsReducer';
import { useAppDispatch } from '~/redux/store';
import { appUserRole } from '~/utils/buildConfig';
import { getParsedAsyncItem, removeAsyncItem, setObjectAsyncItem, storageKeys } from '~/common/asyncStorage';
import { useLoginDetails } from './accounts/LoginDetails';
import { getAccountPhone } from '~/utils/personalDetailsUtils';

export function useBiometrics() {
  const [deviceIsEnrolled, setDeviceIsEnrolled] = useState<boolean>(false);
  const [deviceSensorAvailable, setDeviceSensorAvailable] = useState<boolean>();
  const deviceID = useRef<string>();
  const deviceBiometricType = useRef<BiometryType>();
  const dispatch = useAppDispatch();

  const { details } = useLoginDetails();
  const { updateRegistrationStatus } = useRegistrationSteps();

  const storeBiometricLink = (storeOverride?: { country_code: string; number: string; email?: string }) => {
    const mobile = getAccountPhone(storeOverride ?? details, 'number');
    const email = (storeOverride?.email ?? details?.email)?.toLowerCase();
    const biometricData = mobile ? { mobile } : { email };
    return setObjectAsyncItem(storageKeys.biometrics, biometricData);
  };
  const clearBiometricLink = () => {
    return removeAsyncItem(storageKeys.biometrics);
  };

  const getBiometricLink = () => {
    return getParsedAsyncItem<{ mobile?: string; email?: string }>(storageKeys.biometrics, undefined);
  };

  const biometricLogin = async (details: UserLoginDetailsModel) => {
    const timestamp = Date.now().toString();

    const email = details.email?.toLowerCase();
    await Biometrics.signPayload({
      payload: {
        timestamp,
        device_id: deviceID.current,
        country_code: details.country_code,
        mobile_number: details.number,
        email,
      },
      promptMessage: 'Confirm Biometrics',
    })
      .then(async (certificate) => {
        const biometricLink = details.number
          ? {
              mobile_phone_number: {
                country_code: details.country_code,
                number: details.number,
              },
            }
          : {
              email,
            };

        await apiBiometricLogin({
          ...biometricLink,
          certificate,
          device_id: deviceID.current,
          timestamp,
        })
          .then(async () => {
            const role = appUserRole();
            await apiUseRole({ role });
            await storeBiometricLink({
              country_code: details.country_code,
              number: details.number,
              email,
            });

            // Update login details and registration status
            dispatch(UPDATE_LOGIN_DETAILS({ ...details, steppedUp: true }));
            await updateRegistrationStatus();
          })
          .catch(ErrorAlert);
      })
      .catch((e) => {
        const iosUserCancellation = '-128';
        const androidUserCancellationMessage = 'User cancellation';

        if (e && typeof e.toString === 'function') {
          // Check for user cancellation conditions
          if (e.toString().includes(iosUserCancellation) || e.toString().includes(androidUserCancellationMessage)) {
            console.log('Biometric prompt canceled by user');
            return;
          }

          // If error is not related to cancellation, show the ErrorAlert
          ErrorAlert(e);
        } else {
          console.log('Error object does not exist');
          ErrorAlert('Something went wrong and biometric login could not be used at this time.');
        }
      });
  };

  const enrollDevice = async () => {
    try {
      const success = await Biometrics.showPrompt();
      if (success) {
        const publicKey = await Biometrics.enroll({ overrideKeys: false });
        if (publicKey) {
          await apiEnrollDevice({ device_id: deviceID.current, public_key: publicKey });
          await storeBiometricLink();
          setDeviceIsEnrolled(true);
          SuccessAlert(['Biometrics Enabled']);
        }
      }
    } catch (e) {
      ErrorAlert(e);
    }
  };

  const unenrollDevice = () => {
    Biometrics.removeEnrolment().then(async () => {
      await apiUnenrollDevice({ id: deviceID.current })
        .then(() => {
          clearBiometricLink();
          SuccessAlert(['Biometrics disabled']);
          setDeviceIsEnrolled(false);
        })
        .catch(ErrorAlert);
    });
  };

  useEffect(() => {
    const checkBiometrics = async (sensorAvailable: boolean) => {
      if (!sensorAvailable) return false;

      const linkedBiometricData = await getBiometricLink();

      // Backwards compatible - if no linking data found
      if (!linkedBiometricData) return true;

      const mobile = getAccountPhone(details, 'number');

      if (linkedBiometricData.email) {
        const isEmailLinked = linkedBiometricData.email?.toLowerCase() === details.email?.toLowerCase();
        return isEmailLinked;
      } else if (linkedBiometricData.mobile) {
        const isMobileLinked = linkedBiometricData.mobile === mobile;
        return isMobileLinked;
      }
      return false;
    };

    Biometrics.isEnrolled()
      .then(async (enrolled) => {
        setDeviceIsEnrolled(await checkBiometrics(enrolled));
      })
      .catch(() => setDeviceIsEnrolled(false));
  }, [details.country_code, details.number, details.email]);

  useEffect(() => {
    Biometrics.sensorAvailable()
      .then(setDeviceSensorAvailable)
      .catch(() => {
        setDeviceSensorAvailable(false);
      });

    Biometrics.sensorType()
      .then((biometryType) => {
        deviceBiometricType.current = biometryType;
      })
      .catch(() => {
        deviceBiometricType.current = null;
      });

    if (Device.isDevice) {
      const reactNativeDeviceInfo = require('react-native-device-info');
      reactNativeDeviceInfo.getUniqueId().then((deviceId) => {
        deviceID.current = deviceId;
      });
    }
  }, []);

  return {
    deviceIsEnrolled,
    setDeviceIsEnrolled,
    deviceID,
    deviceSensorAvailable,
    deviceBiometricType,
    enrollDevice,
    unenrollDevice,
    biometricLogin,
  };
}
